@import 'Commun.scss';
@import 'Variables.scss';

.header{
  position:fixed;
  width:100%;
  z-index:999;
  height: 75px;

  .nav{   
    display:flex;
    z-index:999;
    justify-content: space-around;
    align-items: center;
    text-align:center;
    background-color: rgba(255, 254, 254, 0.8);
    box-shadow:0px 1px 5px $grey;
    width:100%;
    height: 100%;
    transition: .3s ease padding;

    .logo{
        display: flex;
        align-items: center;
        vertical-align: middle;

        &:hover{
          cursor:pointer;
        }
    }

    //liens 
    .navcont{
        display:flex;
        list-style: none;
        width:55%; 
        justify-content: space-between;
        
        //un lien 
        .items{
            font-size:14px;
            text-decoration: none;
            color: $black;
            transition: 1s ease text-decoration;

            &.active{
              border-bottom: 1.5px solid $orange;
            }
          
            &:hover{
              cursor: pointer;
              border-bottom: 1.5px solid $orange;
            }  
        }  
        .active{
          border-bottom: 1.5px solid $orange;
        }
        .fill{
          display:none;
        }
    }

    .navbar_burger{
      width: 14px;
      height: 40px;
      border: none;
      background: transparent;
    }

    .compte{
        display:flex;
        text-align:center;
        width: 18%; 
        justify-content: space-between;

        &:hover{
          cursor: pointer;
        }

        .btn-login{
          background:none;
          border:none;
          font-size: 13px;
          color:$black;

          &:hover{
            cursor:pointer;
          }
        }
    }
    .linkedin{
      font-size:16px;
      color: $black;
    }
    .user{
      font-size:16px;
      color: $black;
    }
  }  
}

//Scroll top btn
.btn-top{
  color: #F15a22;
  font-size: 30px;
  position:fixed;
  bottom: 10px;
  right: 10px;
  opacity:0.8;
  z-index:99;

  &:hover{
    opacity:1;
    cursor:pointer;
  }
}

    
//Mobile 
@include mediaXs{
  .header{
    .nav{
      .logo{
        transform: translateY(35px);
        background: $white;
        width:100%;
        box-shadow:0px 1px 5px $grey;

        .geroba{
          transform:scale(0.7); 
        }

        //bouton qui affiche les liens
        .navbar_burger{
          display: block;
          position: fixed;
          top: 1rem;
          right: 1rem;
          background: $orange;
          width:25px;
          height:25px;
          border-radius:50%;
          padding:5px;
        }
      }
      .navcont{ //Mets les liens en bloc les uns sur les autres
        flex-direction: column;
        position: fixed;
        right: -100vw;
        top: 99px;
        padding-top:7px;
        height: 35vh;
        visibility: hidden;
        background-color: $white;
        transition: all .8s ease-out;
        width:55%;
        box-shadow:0px 1px 1px $grey;

        li{
          width: 100%;

          .items{ 
            height: 100%;
            padding: 9px 70% 9px 25%;
            &.active {
              color: $white; 
              background:$orange;
            }
          }
        }
      }

      //Linkedin & Se connecter 
      .compte{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        position:fixed;
        top:0;
        height: 45px;
        color: $white;
        background: $orange;
        width:100%;

        //linkedin
        a, .btn-login{
          margin-right: 20px;
          color: $white;
        }

        //Se connecter
        .btn-login{
          display:none;
        }
      }
      .linkedin{
        color: $white;
      }
      .user{
        color: $white;
      }
    }

    .show-nav .navcont{
      right: 0;
      visibility: visible;
    }

    .navbar_burger:hover{
      cursor: pointer;
    }
    .burger-bar, 
    .burger-bar::before,
    .burger-bar::after{
      display: block;
      width: 15px;
      height: 1px;
      justify-content:center;
      position: relative;
      background: $white;
      transition: all .5s ease-in-out;
    }
    .burger-bar::before,
    .burger-bar::after{
      content:"";
      position: absolute;
      left: 0;
    }
    .burger-bar::before{
     transform: translateY(7px);
    }
    .burger-bar::after{
      transform: translateY(-6px);
    }

    .show-nav .burger-bar{
      width: 0;
      background: transparent;
    }
    .show-nav .burger-bar::before{
      transform: rotate(45deg) translateX(1px) translateY(0.10px);
      width:14px;
    }
    .show-nav .burger-bar::after{
      transform: rotate(-45deg) translateX(0px) translateY(1px);
      width:14px;
      margin-right: 20px;
    }
  }
  .btn-top{
    font-size: 28px;
  }
}

//Mobile 
@include mediaXsLandscape{
  .header{
    .nav{
      .navcont{ //Mets les liens en bloc les uns sur les autres
        height: 75vh !important;
      }

      //Linkedin & Se connecter 
      .compte{
        //Se connecter
        .btn-login{
          display:none;
        }
      }
    }
  }
}

//Tablette iPad Mini 
@include mediaSm{
  .header{
    .nav{
      .logo{
        transform: translateY(40px);
        background: $white;
        width:100%;
        box-shadow:0px 1px 5px $grey;

        .geroba{
          transform:scale(0.7);
        }

        //bouton qui affiche les liens 
        .navbar_burger{
          display: block;
          position: fixed;
          top: 1rem;
          right: 1rem;
          background: $orange;
          width:25px;
          height:25px;
          border-radius:50%;
          padding:5px;
        }
      }
      .navcont{ //Mets les liens en bloc les uns sur les autres
        flex-direction: column;
        position: fixed;
        right: -100vw;
        width: 50%; 
        top: 102px;
        height: 35vh;
        padding: 14px 0;
        visibility: hidden;
        background-color: $white;
        transition: all .8s ease-out;

        
        li{
          //margin:0 !important;

          .items{ //les liens 
            height: 100%;
            width: 100%;
            padding: 14px 70% 14px 25%;

            &.active {
              color: $white; 
              background:$orange;
            }
          }
        }
      }

      //Linkedin & Se connecter 
      .compte{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        color: $white;
        background: $orange;
        position:fixed;
        top:0;
        height: 50px;
        width:100%;     
        //linkedin
        a, .btn-login{
          margin-right: 20px;
          color: $white;
        }
      }
      .linkedin{
        color: $white;
      }
      .user{
        color: $white;
      }
    }

    .show-nav .navcont{
      right: 0;
      visibility: visible;
    }

    .navbar_burger:hover{
      cursor: pointer;
    }
    .burger-bar, 
    .burger-bar::before,
    .burger-bar::after{
      display: block;
      width: 15px;
      height: 1px;
      justify-content:center;
      position: relative;
      background: $white;
      transition: all .5s ease-in-out;
    }
    .burger-bar::before,
    .burger-bar::after{
      content:"";
      position: absolute;
      left: 0;
    }
    .burger-bar::before{
     transform: translateY(7px);
    }
    .burger-bar::after{
      transform: translateY(-6px);
    }

    .show-nav .burger-bar{
      width: 0;
      background: transparent;
    }
    .show-nav .burger-bar::before{
      transform: rotate(45deg) translateX(1px) translateY(0.10px);
      width:14px;
    }
    .show-nav .burger-bar::after{
      transform: rotate(-45deg) translateX(0px) translateY(1px);
      width:14px;
      margin-right: 20px;
    }
  }
  .btn-top{
    font-size: 28px;
  }
}

//Tablette: iPadAir 
@include mediaMd{
  .header{
    .nav{
      .logo{
        transform: translateY(40px);
        background: $white;
        width:100%;
        box-shadow:0px 1px 5px $grey;

        .geroba{
          transform:scale(0.7); //VARIABLE
        }

        //bouton qui affiche les liens 
        .navbar_burger{
          display: block;
          position: fixed;
          top: 1rem;
          right: 1rem;
          background: $orange;
          width:25px;
          height:25px;
          border-radius:50%;
          padding:5px;
        }
      }
      .navcont{ //Mets les liens en bloc les uns sur les autres
        flex-direction: column;
        position: fixed;
        right: -100vw;
        width:50%; //Variable
        top: 104px;
        height: 35vh;
        padding: 14px 0;
        visibility: hidden;
        background-color: $white;
        transition: all .8s ease-out;

        
        li{
          .items{ //les liens 
            height: 100%;
            padding: 15px 70% 15px 25%;

            &.active {
              color: $white; 
              background:$orange;
            }
          }
        }
      }

      //Linkedin & Se connecter 
      .compte{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        color: $white;
        background: $orange;
        position:fixed;
        height: 50px;
        top:0;
        width:100%;
    
        //linkedin
        a, .btn-login{
          margin-right: 20px;
          color: $white;
        }
      }
      .linkedin{
        color: $white;
      }
      .user{
        color: $white;
      }

    }

    .show-nav .navcont{
      right: 0;
      visibility: visible;
    }

    .navbar_burger:hover{
      cursor: pointer;
    }
    .burger-bar, 
    .burger-bar::before,
    .burger-bar::after{
      display: block;
      width: 15px;
      height: 1px;
      justify-content:center;
      position: relative;
      background: $white;
      transition: all .5s ease-in-out;
    }
    .burger-bar::before,
    .burger-bar::after{
      content:"";
      position: absolute;
      left: 0;
    }
    .burger-bar::before{
     transform: translateY(7px);
    }
    .burger-bar::after{
      transform: translateY(-6px);
    }

    .show-nav .burger-bar{
      width: 0;
      background: transparent;
    }
    .show-nav .burger-bar::before{
      transform: rotate(45deg) translateX(1px) translateY(0.10px);
      width:14px;
    }
    .show-nav .burger-bar::after{
      transform: rotate(-45deg) translateX(0px) translateY(1px);
      width:14px;
      margin-right: 20px;
    }
  }
}


//Ecran large 
@include mediaXl{
  .header{
    .nav{
      height: $headerXl;
      .navcont{
        .items{
          font-size: $fontsizeLg;
          &.active{
            border-bottom: 2px solid $orange;
          }
        
          &:hover{
            cursor: pointer;
            border-bottom: 2px solid $orange;
          }  
        }
      }
      .compte{
        width:18%;
        .btn-login{
          margin-right:20px;
          font-size:15.5px;
        }
      }
      .linkedin{
        font-size:17px;
        color: $black;
      }
      .user{
        font-size:17px;
      }
    }
  }
  .btn-top{
    font-size: 32px;
  }
}


@include mediaXxl{
  .header{
    .nav{
      height: $headerXxl; 
      .logo{
        padding-top:20px;
        transform: scale(1.2);
      }
      .navcont{
        .items{
          font-size: 30px;

          &.active{
            border-bottom: 2.5px solid $orange;
          }
        
          &:hover{
            cursor: pointer;
            border-bottom: 2.5px solid $orange;
          }  
        }
      }
      .compte{
        width:10%;
        .btn-login{
          margin-right:20px; 
          font-size: 28px;
        }
      }
      .linkedin{
        font-size:30px;
        color: $black;
      }
      .user{
        font-size: 28px;
      }
    }
  }
  .btn-top{
    font-size: 50px;
  }
}