@import "Variables.scss";
@import "Commun.scss";

.slick-slide{
    padding: 10px 0;
}

//coloré sur hover
.slick-slide img{
    -webkit-filter: grayscale(100%); //N&B
    filter: grayscale(100%);
    &:hover{
        -webkit-filter: none;
        filter:none;
        transform:scale(1.05);
    }
}

//Flèches personalisées 
.slick-next {
    background: url('../images/right-arrow.svg') center center no-repeat !important;
    &::before {
      display: none;
    }
}
.slick-prev {
    background: url('../images/left-arrow.svg') center center no-repeat !important;
    &::before {
        display: none;
    }
}

.pclients{
    text-align:center;
    padding: 50px 0;
    color: $orange;
}

.cards{
    margin:0px auto;
    width: 88%;
    height: 145px;

    .card{
        padding:10px 0px;
        margin: 0 auto;
        height: 145px;
        position: relative;
        justify-content: center;

        .clientLogo{ //fit the image 
            max-width: 100%;
            max-height: 100%;
            display: block;
            position: absolute;
            margin: auto; //ajout
            object-fit: cover;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
    } 
}