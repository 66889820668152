@import "Variables.scss";

.video-container {
    position: relative;
    width:95%;
    height:600px;
    height: auto;
    margin:auto;

    .videoDemo{
        width: 100%;
        height: 100%;
        border: none;
        aspect-ratio: 16 / 9;
    }

    //Alt images
    img{
        width: 100%;
        height: 100%;
    }
}
