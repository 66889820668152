@import 'Commun.scss';
@import "Variables.scss";

.slider{
  width:100%;
  height: 95vh;
  //max-height: 1306px;
  position: relative;
  overflow: hidden;

  .textslide{
      position: absolute;
      right: 30%;
      bottom: 18%;
      width: 100px;
      line-height: 80px;

      h1{
        font-size:70px; 
        color: $white;
        text-shadow:0.5px 0.5px $shadowgrey;
      }    
  }

  .container-dots {
    display: flex;
    gap: 10px;
    position: absolute;
    bottom: 3%;
    left: 50%;
    transform: translateX(-50%);

    .dot {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background: $white;
    }

    .dot.active {
      background: $orange;
    }
  } 
}

.invisible{
  display: none;
}

.slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transform: translateX(50%); 
  transition: all .5s ease;
}

.current {
  opacity: 1;
  transform: translateX(0);
}

.slide img{
  width: 100%;
  height: 100%;
}


//Ecran mobile 
@include mediaXs{
  .slider{
    transform: translateY(85px);
    height:28vh;
    .textslide{
      line-height:35px;
      h1{
        font-size: 32px; 
      }    
    }
    .container-dots {
      bottom: 4%;
      .dot {
        width: 11px;
        height: 11px;
      }
    } 
  }
}

@include mediaXsLandscape{
  .slider{
    transform: translateY(45px) !important;
    height:90vh !important;
  }
}

@include mediaSm{
  .slider{
    transform: translateY(55px);
    height:42vh;
    .textslide{
      line-height:60px;
      h1{
        font-size: 60px; 
      }    
    }
    .container-dots {
      bottom: 5%;
    } 
  }
}

//iPad Air
@include mediaMd{
  .slider{
    transform: translateY(50px);
    height: 42vh;
    .textslide{
      line-height:60px;
      h1{
        font-size:55px; 
      }    
    }
    .container-dots {
      bottom: 5%;
    } 
  }
}


//Responsive écran large
@include mediaXl{
  .slider{
    .textslide{
      line-height:100px;
      h1{
        font-size:100px;  
      }    
    }
    .container-dots{
      .dot{
        width: 23px;
        height: 23px;
      }
    }
  }
}

@include mediaXxl{
  .slider{
    .textslide{
      line-height:185px;
      h1{
        font-size:150px; 
      }    
    }
    .container-dots{
      .dot{
        width: 30px;
        height: 30px;
      }
    }
  }
}