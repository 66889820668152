.ol-map {
    width: 100%;
    height: 100%; //CHANGE
}
.ol-control {
  position: absolute;
  border-radius: 4px;
  padding: 2px;
}
.ol-full-screen {
  top: .5em;
  right: .5em;
}
