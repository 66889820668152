@import "Variables.scss";

body .active-modal {
    overflow-y: hidden;
}

.modal, .overlay {
    z-index: 999;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    position: fixed;
    overflow: scroll;
    background: rgba(255, 254, 254, 0.8);
    .PopUp{
        z-index: 99999;
        position: absolute;
        top:20px;
        left:0;
        right:0;
        margin-left: auto;
        margin-right: auto;
        background: $white;
        box-shadow: 0px 0px 5px 5px grey;
        width:70%;
        border-radius:5px;
        .close-modal{
            z-index:9999;
            position:absolute;
            top:10px;
            right:10px;
            background:none;
            font-size:18px;
            padding:5px 10px;
            &:hover{
                cursor:pointer;
                color: $white;
                background: $orange;
                border:none;
            }
        }
        img{
            max-width: 100%;
            width: 600px;
            margin: auto;
            position: relative;
            display: block;
            margin-bottom: 20px;
        }
        .imageDeFond {
            position: absolute;
            margin-left: 2%;
            -webkit-filter: blur(20px);
            -moz-filter: blur(20px);
            -o-filter: blur(20px);
            -ms-filter: blur(20px);
            filter: blur(20px);
        }
        .imageDeFond2 {
            position: absolute;
            right: 2%;
            -webkit-filter: blur(20px);
            -moz-filter: blur(20px);
            -o-filter: blur(20px);
            -ms-filter: blur(20px);
            filter: blur(20px);
        }
        .title {
            text-align:left;
            margin-left: 3%;
            margin-top: 20px !important;
        }
        .lightTitle{
            margin-left: 3%;
            margin-top: 40px !important;
        }
    }  
}

@include mediaXs{
    .modal{
        .PopUp{
            top: 40px;
            width:95%;
            .lightTitle{
                font-size: 22px !important;
                margin-top: 20px;
            }
        }
    }
}

@include mediaSm{
    .modal{
        .PopUp{
            top: 100px;
            width:95%;
        }
    }
}

@include mediaMd{
    .modal{
        .PopUp{
            top: 130px;
            width:95%;
            .lightTitle{
                font-size: 22px;
                margin-top: 20px !important; 
            }
        }
    }
}